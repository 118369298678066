import YokyGlo1 from "../../assets/images/showcase/yoky_webp/glo/home.webp";
import YokyGlo1_MR from "../../assets/images/showcase/yoky_webp/glo/home_mr.webp";
import YokyGlo1_LR from "../../assets/images/showcase/yoky_webp/glo/home_lr.webp";

import YokyGloMarketing1 from "../../assets/images/showcase/yoky_webp/glo/2.webp";
import YokyGloMarketing1_MR from "../../assets/images/showcase/yoky_webp/glo/2_mr.webp";
import YokyGloMarketing1_LR from "../../assets/images/showcase/yoky_webp/glo/2_lr.webp";

import YokyGloMarketing2 from "../../assets/images/showcase/yoky_webp/glo/3.webp";
import YokyGloMarketing2_MR from "../../assets/images/showcase/yoky_webp/glo/3_mr.webp";
import YokyGloMarketing2_LR from "../../assets/images/showcase/yoky_webp/glo/3_lr.webp";

import YokyGloMarketing3 from "../../assets/images/showcase/yoky_webp/glo/4.webp";
import YokyGloMarketing3_MR from "../../assets/images/showcase/yoky_webp/glo/4_mr.webp";
import YokyGloMarketing3_LR from "../../assets/images/showcase/yoky_webp/glo/4_lr.webp";

import YokyGloMarketing4 from "../../assets/images/showcase/yoky_webp/glo/5.webp";
import YokyGloMarketing4_MR from "../../assets/images/showcase/yoky_webp/glo/5_mr.webp";
import YokyGloMarketing4_LR from "../../assets/images/showcase/yoky_webp/glo/5_lr.webp";

import YokyGloMarketing5 from "../../assets/images/showcase/yoky_webp/glo/6.webp";
import YokyGloMarketing5_MR from "../../assets/images/showcase/yoky_webp/glo/6_mr.webp";
import YokyGloMarketing5_LR from "../../assets/images/showcase/yoky_webp/glo/6_lr.webp";

import YokyGloMarketing6 from "../../assets/images/showcase/yoky_webp/glo/7.webp";
import YokyGloMarketing6_MR from "../../assets/images/showcase/yoky_webp/glo/7_mr.webp";
import YokyGloMarketing6_LR from "../../assets/images/showcase/yoky_webp/glo/7_lr.webp";

import YokyGloMarketing7 from "../../assets/images/showcase/yoky_webp/glo/8.webp";
import YokyGloMarketing7_MR from "../../assets/images/showcase/yoky_webp/glo/8_mr.webp";
import YokyGloMarketing7_LR from "../../assets/images/showcase/yoky_webp/glo/8_lr.webp";

import YokyGloApp from "../../assets/images/showcase/yoky_webp/glo/10.webp";
import YokyGloApp_MR from "../../assets/images/showcase/yoky_webp/glo/10_mr.webp";
import YokyGloApp_LR from "../../assets/images/showcase/yoky_webp/glo/10_lr.webp";

import YokyGloFeatures0 from "../../assets/images/showcase/yoky_webp/glo/9.webp";
import YokyGloFeatures0_MR from "../../assets/images/showcase/yoky_webp/glo/9_mr.webp";
import YokyGloFeatuers0_LR from "../../assets/images/showcase/yoky_webp/glo/9_lr.webp";

import YokyGloFeatures1 from "../../assets/images/showcase/yoky_webp/glo/features1.webp";
import YokyGloFeatures1_MR from "../../assets/images/showcase/yoky_webp/glo/features1_mr.webp";
import YokyGloFeatuers1_LR from "../../assets/images/showcase/yoky_webp/glo/features1_lr.webp";

import YokyGloFeatures2 from "../../assets/images/showcase/yoky_webp/glo/features2.webp";
import YokyGloFeatures2_MR from "../../assets/images/showcase/yoky_webp/glo/features2_mr.webp";
import YokyGloFeatuers2_LR from "../../assets/images/showcase/yoky_webp/glo/features2_lr.webp";

const yokyGloData = [
    {
        type: "slide",
        id: "1",
        imageSrc: YokyGlo1,
        imageSrc_MR: YokyGlo1_MR,
        bgImageSrc: YokyGlo1_LR,
        alt: "Home",
        linkUrl: "https://yoky.io/tag/#home",
    },
    {
        type: "slide",
        id: "2",
        imageSrc: YokyGloMarketing1,
        imageSrc_MR: YokyGloMarketing1_MR,
        bgImageSrc: YokyGloMarketing1_LR,
        alt: "Features",
    },
    {
        type: "slide",
        id: "3",
        imageSrc: YokyGloMarketing2,
        imageSrc_MR: YokyGloMarketing2_MR,
        bgImageSrc: YokyGloMarketing2_LR,
        alt: "Features",
    },
    {
        type: "slide",
        id: "4",
        imageSrc: YokyGloApp,
        imageSrc_MR: YokyGloApp_MR,
        bgImageSrc: YokyGloApp_LR,
        alt: "Android & iOS App",
    },
    {
        type: "slide",
        id: "5",
        imageSrc: YokyGloFeatures0,
        imageSrc_MR: YokyGloFeatures0_MR,
        bgImageSrc: YokyGloFeatuers0_LR,
        alt: "Features",
    },

    {
        type: "slide",
        id: "6",
        imageSrc: YokyGloMarketing3,
        imageSrc_MR: YokyGloMarketing3_MR,
        bgImageSrc: YokyGloMarketing3_LR,
        alt: "Features",
    },
    {
        type: "slide",
        id: "7",
        imageSrc: YokyGloMarketing4,
        imageSrc_MR: YokyGloMarketing4_MR,
        bgImageSrc: YokyGloMarketing4_LR,
        alt: "Features",
    },
    {
        type: "slide",
        id: "8",
        imageSrc: YokyGloMarketing5,
        imageSrc_MR: YokyGloMarketing5_MR,
        bgImageSrc: YokyGloMarketing5_LR,
        alt: "Features",
    },
    {
        type: "slide",
        id: "9",
        imageSrc: YokyGloMarketing6,
        imageSrc_MR: YokyGloMarketing6_MR,
        bgImageSrc: YokyGloMarketing6_LR,
        alt: "Features",
    },
    {
        type: "slide",
        id: "10",
        imageSrc: YokyGloMarketing7,
        imageSrc_MR: YokyGloMarketing7_MR,
        bgImageSrc: YokyGloMarketing7_LR,
        alt: "Features",
    },

    {
        type: "slide",
        id: "11",
        imageSrc: YokyGloFeatures1,
        imageSrc_MR: YokyGloFeatures1_MR,
        bgImageSrc: YokyGloFeatuers1_LR,
        alt: "Specifications",
    },
    {
        type: "slide",
        id: "12",
        imageSrc: YokyGloFeatures2,
        imageSrc_MR: YokyGloFeatures2_MR,
        bgImageSrc: YokyGloFeatuers2_LR,
        alt: "Specifications",
    },
];
export default yokyGloData;
