import YokyRed1 from "../../assets/images/showcase/yoky_webp/red/home.webp";
import YokyRed1_MR from "../../assets/images/showcase/yoky_webp/red/home_mr.webp";
import YokyRed1_LR from "../../assets/images/showcase/yoky_webp/red/home_lr.webp";

import YokyRedMarketing1 from "../../assets/images/showcase/yoky_webp/red/7.webp";
import YokyRedMarketing1_MR from "../../assets/images/showcase/yoky_webp/red/7_mr.webp";
import YokyRedMarketing1_LR from "../../assets/images/showcase/yoky_webp/red/7_lr.webp";

import YokyRedMarketing2 from "../../assets/images/showcase/yoky_webp/red/6.webp";
import YokyRedMarketing2_MR from "../../assets/images/showcase/yoky_webp/red/6_mr.webp";
import YokyRedMarketing2_LR from "../../assets/images/showcase/yoky_webp/red/6_lr.webp";

import YokyRedApp from "../../assets/images/showcase/yoky_webp/red/5.webp";
import YokyRedApp_MR from "../../assets/images/showcase/yoky_webp/red/5_mr.webp";
import YokyRedApp_LR from "../../assets/images/showcase/yoky_webp/red/5_lr.webp";

import YokyRedFeatures0 from "../../assets/images/showcase/yoky_webp/red/2.webp";
import YokyRedFeatures0_MR from "../../assets/images/showcase/yoky_webp/red/2_mr.webp";
import YokyRedFeatuers0_LR from "../../assets/images/showcase/yoky_webp/red/2_lr.webp";

import YokyRedFeatures1 from "../../assets/images/showcase/yoky_webp/red/features1.webp";
import YokyRedFeatures1_MR from "../../assets/images/showcase/yoky_webp/red/features1_mr.webp";
import YokyRedFeatuers1_LR from "../../assets/images/showcase/yoky_webp/red/features1_lr.webp";

import YokyRedFeatures2 from "../../assets/images/showcase/yoky_webp/red/features2.webp";
import YokyRedFeatures2_MR from "../../assets/images/showcase/yoky_webp/red/features2_mr.webp";
import YokyRedFeatuers2_LR from "../../assets/images/showcase/yoky_webp/red/features2_lr.webp";

const yokyRedData = [
    {
        type: "slide",
        id: "1",
        imageSrc: YokyRed1,
        imageSrc_MR: YokyRed1_MR,
        bgImageSrc: YokyRed1_LR,
        alt: "Home",
        linkUrl: "https://yoky.io/tag/#home",
    },
    {
        type: "slide",
        id: "2",
        imageSrc: YokyRedMarketing1,
        imageSrc_MR: YokyRedMarketing1_MR,
        bgImageSrc: YokyRedMarketing1_LR,
        alt: "Features",
    },
    {
        type: "slide",
        id: "3",
        imageSrc: YokyRedMarketing2,
        imageSrc_MR: YokyRedMarketing2_MR,
        bgImageSrc: YokyRedMarketing2_LR,
        alt: "Features",
    },
    {
        type: "slide",
        id: "4",
        imageSrc: YokyRedApp,
        imageSrc_MR: YokyRedApp_MR,
        bgImageSrc: YokyRedApp_LR,
        alt: "Android & iOS App",
    },
    {
        type: "slide",
        id: "5",
        imageSrc: YokyRedFeatures0,
        imageSrc_MR: YokyRedFeatures0_MR,
        bgImageSrc: YokyRedFeatuers0_LR,
        alt: "Features",
    },
    {
        type: "slide",
        id: "6",
        imageSrc: YokyRedFeatures1,
        imageSrc_MR: YokyRedFeatures1_MR,
        bgImageSrc: YokyRedFeatuers1_LR,
        alt: "Specifications",
    },
    {
        type: "slide",
        id: "7",
        imageSrc: YokyRedFeatures2,
        imageSrc_MR: YokyRedFeatures2_MR,
        bgImageSrc: YokyRedFeatuers2_LR,
        alt: "Specifications",
    },
];
export default yokyRedData;
